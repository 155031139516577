<template>
  <div>
    <div :class="`my-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Challenge Solutions</h1>
      <p>
        Use our platform to create engaging corporate fitness challenges. What type of challenge are you looking for?
      </p>
    </div>
    
    <div>
      <VideoEmbed videoId="LyoxH7OLMb0" />
    </div>

    <div class="white--text pb-6 pb-md-12">
      <div class="containerZ">
        <v-row >
          <v-col v-for="(item, idx) in navData['solutions-menu-cofi'].filter(x => x.highlight)" :key="idx" cols="12" md="6">
            <v-card :color="$helpers.getGradientColorAt('#00B46E', '#FFEB3B', 1-idx/ navData['solutions-menu-cofi'].length-1)" :to="item.to">
              <v-card-text class="pb-0"><v-icon size="100">fadl fa {{item.icon}}</v-icon></v-card-text>
              <v-card-title class="headline">{{ item.text }}</v-card-title>
              <v-card-text>
                {{ item.description }}
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="item.to">More details</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Create engaging and beautiful experiences!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your challenge</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import VideoEmbed from "@/components/VideoEmbed";
import navData from '@/data/nav.json'

export default {
  name: "Contact",
  components: {
    VideoEmbed,
  },
  props: {
  },
  data: function() {
    return {
      navData: navData,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
   
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>